﻿// DataView
@media (min-width: 1200px) {
    .ui-dataview.ui-dataview-list > .ui-dataview-content > div.ui-g > div.col-lg-6 {
        width: 50%;
    }
}

// Paginator
.ui-paginator-bottom {
    padding: 0;
}

.ui-paginator .ui-paginator-current,
.ui-paginator .ui-paginator-first,
.ui-paginator .ui-paginator-last,
.ui-paginator .ui-paginator-page,
.ui-paginator .ui-paginator-pages {
    color: $grey-dark;
}

.ui-paginator .ui-state-active,
.ui-paginator .ui-state-active:hover {
    background: none;
    color: $black;
}

.ui-paginator .ui-state-default {
    border: none;
}

.ui-paginator-prev .ui-paginator-icon.pi.pi-caret-left,
.ui-paginator-next .ui-paginator-icon.pi.pi-caret-right {
    font-family: FontAwesome;
    font-size: 1.5em;
    color: $grey-dark;
}

.ui-paginator-prev .ui-paginator-icon.pi.pi-caret-left:before {
    content: "\f104";
}

.ui-paginator-next .ui-paginator-icon.pi.pi-caret-right:before {
    content: "\f105";
}

// Table
.ui-table {
    font-size: 12px
}

.ui-table-summary {
    padding-top: 1.5em;
    padding-bottom: 1.5em;
    background: $grey-blue-lighter;
}

.ui-table .ui-table-tbody > tr > td:first-of-type,
.ui-table .ui-table-tfoot > tr > td:first-of-type,
.ui-table .ui-table-thead > tr > th:first-of-type {
    padding-left: .75em;
}

@media screen and (max-width: 40em) {
    .ui-table .ui-table-tbody > tr > td:first-of-type,
    .ui-table .ui-table-tfoot > tr > td:first-of-type,
    .ui-table .ui-table-thead > tr > th:first-of-type {
        padding-left: .4em;
    }
}

.ui-table .ui-table-tfoot > tr > td, .ui-table .ui-table-thead > tr > th {
    height: 50px
}

.ui-table .ui-table-thead > tr > th {
    opacity: .5;
    border-bottom: 2px solid #eee;
}

.ui-table .ui-table-tbody > tr {
    font-size: 13px;
    color: #848e96;
    height: 50px
}

.ui-table .ui-table-tbody > tr:nth-of-type(odd) {
    background-color: #f9f9f9;
}

// Dialog
.ui-dialog, .ui-dialog-footer {
    border-radius: 12px !important;
}

.ui-dialog .ui-dialog-content {
    overflow: hidden;
}

// Toast
.ui-toast-icon.pi.pi-times,
.ui-toast-icon.pi.pi-info-circle,
.ui-toast-icon.pi.pi-exclamation-triangle,
.ui-toast-icon.pi.pi-check {
    font-family: FontAwesome;
    font-size: 1.5em;
}

.ui-toast-icon.pi.pi-times:before {
    content: "\f057";
}

.ui-toast-icon.pi.pi-info-circle:before {
    content: "\f05a";
}

.ui-toast-icon.pi.pi-exclamation-triangle:before {
    content: "\f071";
}

.ui-toast-icon.pi.pi-check:before {
    content: "\f058";
}

.ui-corner-top {
    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    border-top-left-radius: 0;
    -moz-border-radius-topright: 0;
    -webkit-border-top-right-radius: 0;
    border-top-right-radius: 0;
}
