@charset "UTF-8";
/* URL locations */
/* Colors */
/* Global Box Shadow */
/*Spacing*/
/* Global Class */
@import url(~primeng/resources/primeng.css);
.hidden {
  display: none !important; }

.row {
  display: table;
  margin-left: -15px;
  margin-right: -15px;
  width: 100%; }

.half-col {
  float: left;
  width: 50%;
  padding-left: 15px;
  padding-right: 15px; }

.strong, strong {
  font-weight: bold; }

.semi-strong {
  font-weight: 600; }

.margin-top-1 {
  margin-top: 5px; }

.margin-top-2 {
  margin-top: 10px; }

.margin-top-3 {
  margin-top: 15px; }

.margin-top-4 {
  margin-top: 20px; }

.margin-top-5 {
  margin-top: 25px; }

.margin-top-6 {
  margin-top: 30px; }

/*Font Size*/
/*Font line height*/
.btn-link {
  color: #00a3e0;
  text-decoration: none; }

/*
* Deprecated: Use nova instead
*/
.ui-widget {
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 1em; }
  .ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1em; }
  .ui-widget :active {
    outline: none; }

.ui-widget-content {
  border: 1px solid transparent;
  background-color: #fff;
  color: #333333; }
  .ui-widget-content a {
    color: #333333; }

.ui-widget-header {
  border: 0px solid transparent;
  background-color: transparent;
  color: #334750;
  font-weight: bold; }
  .ui-widget-header a {
    color: #334750; }

.ui-widget-overlay {
  background-color: #666666;
  opacity: .50;
  filter: Alpha(Opacity=50); }

.ui-state-default {
  border: 1px solid transparent;
  background-color: #fff;
  color: #86bc25; }
  .ui-state-default a {
    color: #86bc25; }

.ui-state-active {
  border-color: #86bc25;
  background-color: #EDF5DF;
  color: #fff; }
  .ui-state-active a {
    color: #fff; }

.ui-state-highlight {
  border-color: #edf5df;
  background-color: #fff;
  color: #86bc25; }
  .ui-state-highlight a {
    color: #86bc25; }

.ui-state-focus {
  border-color: #74b2e2;
  background-color: #e4f1fb;
  color: #0070a3; }
  .ui-state-focus a {
    color: #0070a3; }

.ui-state-error {
  border-color: #f10031;
  background-color: transparent;
  color: #fff; }
  .ui-state-error a {
    color: #fff; }

.ui-state-disabled,
.ui-widget:disabled {
  opacity: 0.45;
  filter: Alpha(Opacity=45);
  background-image: none;
  cursor: default !important; }
  .ui-state-disabled *,
  .ui-widget:disabled * {
    cursor: default !important; }

/* Forms */
.ui-inputtext {
  background-color: transparent;
  color: #333333; }

.ui-inputtext:enabled:hover {
  border-color: #d8d8d8; }

.ui-inputtext.ui-state-focus,
.ui-inputtext:focus {
  outline: 0 none;
  border-color: #74b2e2;
  -moz-box-shadow: 0px 0px 5px #74b2e2;
  -webkit-box-shadow: 0px 0px 5px #74b2e2;
  box-shadow: 0px 0px 5px #74b2e2; }

.ui-inputgroup .ui-inputgroup-addon {
  border-color: transparent;
  background-color: rgba(26, 25, 25, 0);
  color: #2779aa; }
  .ui-inputgroup .ui-inputgroup-addon:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px; }
  .ui-inputgroup .ui-inputgroup-addon:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px; }

.ui-inputgroup .ui-button:first-child {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px; }

.ui-inputgroup .ui-button:last-child {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px; }

.ui-float-label input.ng-dirty.ng-invalid ~ label {
  color: #fff; }

.ui-autocomplete .ui-autocomplete-multiple-container:not(.ui-state-disabled):hover {
  border-color: #d8d8d8; }

.ui-autocomplete .ui-autocomplete-multiple-container:not(.ui-state-disabled).ui-state-focus {
  border-color: #74b2e2; }

.ui-chips > ul:not(.ui-state-disabled):hover {
  border-color: #d8d8d8; }

.ui-chips > ul:not(.ui-state-disabled).ui-state-focus {
  border-color: #74b2e2; }

.ui-button:focus,
.ui-button:enabled:hover,
.ui-fileupload-choose:not(.ui-state-disabled):hover {
  outline: 0 none;
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-button:focus a,
  .ui-button:enabled:hover a,
  .ui-fileupload-choose:not(.ui-state-disabled):hover a {
    color: #86bc25; }

.ui-button:enabled:active,
.ui-fileupload-choose:not(.ui-state-disabled):active {
  border-color: #86bc25;
  background-color: #EDF5DF;
  color: #fff; }

.ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-chkbox-box:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #86bc25; }

.ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-radiobutton-box:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #86bc25; }

.ui-dropdown .ui-dropdown-clear-icon {
  color: #999999; }

.ui-dropdown:not(.ui-state-disabled):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-dropdown:not(.ui-state-disabled):hover a {
    color: #86bc25; }

.ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-dropdown-panel .ui-dropdown-item:not(.ui-state-highlight):not(.ui-state-disabled):hover a {
    color: #86bc25; }

.ui-listbox .ui-listbox-header .ui-listbox-filter-container .fa {
  color: #333333; }

.ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-listbox:not(.ui-state-disabled) .ui-listbox-item:not(.ui-state-highlight):not(.ui-state-disabled):hover a {
    color: #86bc25; }

.ui-listbox:not(.ui-state-disabled) .ui-listbox-item.ui-state-disabled .ui-chkbox-box:hover {
  background-color: transparent;
  border-color: transparent; }

.ui-listbox.ui-state-disabled .ui-chkbox-box:not(.ui-state-active):hover {
  border-color: transparent;
  background-color: #fff;
  color: #86bc25; }

.ui-multiselect:not(.ui-state-disabled):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-multiselect:not(.ui-state-disabled):hover a {
    color: #86bc25; }

.ui-multiselect-panel .ui-multiselect-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-multiselect-panel .ui-multiselect-item:not(.ui-state-highlight):not(.ui-state-disabled):hover a {
    color: #86bc25; }

.ui-multiselect-panel .ui-multiselect-item.ui-state-disabled:hover .ui-chkbox-box {
  background-color: transparent;
  border-color: transparent; }

.ui-multiselect-panel .ui-multiselect-close {
  color: #222222; }

.ui-multiselect-panel .ui-multiselect-filter-container .fa {
  color: #333333; }

.ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:hover a {
    color: #86bc25; }

.ui-spinner:not(.ui-state-disabled) .ui-spinner-button:enabled:active {
  border-color: #86bc25;
  background-color: #EDF5DF;
  color: #fff; }

.ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-selectbutton .ui-button:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #86bc25; }

.ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-togglebutton:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #86bc25; }

.ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-paginator a:not(.ui-state-disabled):not(.ui-state-active):hover a {
    color: #86bc25; }

.ui-paginator a {
  color: #86bc25; }

.ui-datatable .ui-rowgroup-header a {
  color: #334750; }

.ui-datatable .ui-sortable-column:not(.ui-state-active):hover {
  background-color: #fff;
  color: #86bc25; }

.ui-datatable .ui-row-toggler {
  color: #333333; }

.ui-datatable tbody.ui-datatable-hoverable-rows > tr.ui-widget-content:not(.ui-state-highlight):hover {
  cursor: pointer;
  background-color: #fff;
  color: #86bc25; }

.ui-orderlist .ui-orderlist-item:not(.ui-state-highlight):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-orderlist .ui-orderlist-item:not(.ui-state-highlight):hover a {
    color: #86bc25; }

.ui-picklist .ui-picklist-item:not(.ui-state-disabled):not(.ui-state-highlight):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-picklist .ui-picklist-item:not(.ui-state-disabled):not(.ui-state-highlight):hover a {
    color: #86bc25; }

.ui-picklist .ui-picklist-droppoint-highlight {
  border-color: #edf5df;
  background-color: #fff;
  color: #303030; }
  .ui-picklist .ui-picklist-droppoint-highlight a {
    color: #303030; }

.ui-picklist .ui-picklist-highlight {
  border-color: #edf5df;
  color: #303030; }
  .ui-picklist .ui-picklist-highlight a {
    color: #303030; }

.ui-tree.ui-treenode-dragover {
  border-color: #edf5df; }

.ui-tree .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-tree .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover a {
    color: #86bc25; }

.ui-tree .ui-treenode-content.ui-treenode-dragover {
  background-color: #EDF5DF;
  color: #fff; }

.ui-tree.ui-tree-horizontal .ui-treenode-content.ui-treenode-selectable .ui-treenode-label:not(.ui-state-highlight):hover {
  background-color: inherit;
  color: inherit; }

.ui-tree.ui-tree-horizontal .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-tree.ui-tree-horizontal .ui-treenode-content.ui-treenode-selectable:not(.ui-state-highlight):hover a {
    color: #86bc25; }

.ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-organizationchart .ui-organizationchart-node-content.ui-organizationchart-selectable-node:not(.ui-state-highlight):hover a {
    color: #86bc25; }

.ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-accordion .ui-accordion-header:not(.ui-state-active):not(.ui-state-disabled):hover a {
    color: #86bc25; }

.ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend:hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-fieldset.ui-fieldset-toggleable .ui-fieldset-legend:hover a {
    color: #86bc25; }

.ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-panel .ui-panel-titlebar .ui-panel-titlebar-icon:hover a {
    color: #86bc25; }

.ui-tabview .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-tabview .ui-tabview-nav li:not(.ui-state-active):not(.ui-state-disabled):hover a {
    color: #86bc25; }

.ui-dialog .ui-dialog-titlebar-icon {
  color: #334750; }
  .ui-dialog .ui-dialog-titlebar-icon:hover {
    border-color: #d8d8d8;
    background-color: #fff;
    color: #86bc25; }
    .ui-dialog .ui-dialog-titlebar-icon:hover a {
      color: #86bc25; }

.ui-sidebar .ui-sidebar-close {
  color: #334750; }
  .ui-sidebar .ui-sidebar-close:hover {
    border-color: #d8d8d8;
    background-color: #fff;
    color: #86bc25; }
    .ui-sidebar .ui-sidebar-close:hover a {
      color: #86bc25; }

.ui-overlaypanel .ui-overlaypanel-close:hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-overlaypanel .ui-overlaypanel-close:hover a {
    color: #86bc25; }

.ui-inplace .ui-inplace-display:hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-inplace .ui-inplace-display:hover a {
    color: #86bc25; }

.ui-breadcrumb a {
  color: #334750; }

.ui-menuitem .ui-menuitem-link {
  color: #333333; }
  .ui-menuitem .ui-menuitem-link:hover {
    border-color: #d8d8d8;
    background-color: #fff;
    color: #86bc25;
    border-color: transparent; }
    .ui-menuitem .ui-menuitem-link:hover a {
      color: #86bc25; }

.ui-menuitem.ui-menuitem-active > .ui-menuitem-link {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25;
  border-color: transparent; }
  .ui-menuitem.ui-menuitem-active > .ui-menuitem-link a {
    color: #86bc25; }

.ui-tabmenu .ui-tabmenu-nav li:not(.ui-state-active):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-tabmenu .ui-tabmenu-nav li:not(.ui-state-active):hover a {
    color: #86bc25; }

.ui-steps .ui-steps-item:not(.ui-state-highlight):not(.ui-state-disabled):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-steps .ui-steps-item:not(.ui-state-highlight):not(.ui-state-disabled):hover a {
    color: #86bc25; }

.ui-panelmenu .ui-panelmenu-header:not(.ui-state-active):hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25;
  border-color: transparent; }
  .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active):hover a {
    color: #86bc25; }
  .ui-panelmenu .ui-panelmenu-header:not(.ui-state-active):hover a {
    color: #86bc25; }

.ui-panelmenu .ui-panelmenu-header.ui-state-active a {
  color: #fff; }

.ui-panelmenu .ui-panelmenu-content .ui-menuitem-link {
  color: #333333; }
  .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link:hover {
    border-color: #d8d8d8;
    background-color: #fff;
    color: #86bc25;
    border-color: transparent; }
    .ui-panelmenu .ui-panelmenu-content .ui-menuitem-link:hover a {
      color: #86bc25; }

.ui-datepicker .ui-datepicker-header a {
  color: #334750; }
  .ui-datepicker .ui-datepicker-header a:hover {
    border-color: #d8d8d8;
    background-color: #fff;
    color: #86bc25; }
    .ui-datepicker .ui-datepicker-header a:hover a {
      color: #86bc25; }

.ui-datepicker .ui-datepicker-calendar td:not(.ui-state-disabled) a:hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-datepicker .ui-datepicker-calendar td:not(.ui-state-disabled) a:hover a {
    color: #86bc25; }

.ui-datepicker .ui-monthpicker .ui-monthpicker-month:hover {
  border-color: #d8d8d8;
  background-color: #fff;
  color: #86bc25; }
  .ui-datepicker .ui-monthpicker .ui-monthpicker-month:hover a {
    color: #86bc25; }

.fc .fc-toolbar .fc-prev-button .ui-icon-circle-triangle-w {
  margin-top: .3em;
  background: none !important;
  font-family: 'primeicons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center; }
  .fc .fc-toolbar .fc-prev-button .ui-icon-circle-triangle-w:before {
    content: ""; }

.fc .fc-toolbar .fc-next-button .ui-icon-circle-triangle-e {
  margin-top: .3em;
  background: none !important;
  font-family: 'primeicons';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  display: inline-block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: 0px !important;
  text-align: center; }
  .fc .fc-toolbar .fc-next-button .ui-icon-circle-triangle-e:before {
    content: ""; }

.ui-rating a {
  color: #333333; }

.ui-organizationchart .ui-organizationchart-line-down {
  background-color: rgba(0, 0, 0, 0); }

.ui-organizationchart .ui-organizationchart-line-left {
  border-right: 1px solid rgba(0, 0, 0, 0); }

.ui-organizationchart .ui-organizationchart-line-top {
  border-top: 1px solid rgba(0, 0, 0, 0); }

.ui-organizationchart .ui-organizationchart-node-content {
  border-color: rgba(0, 0, 0, 0); }

.ui-organizationchart .ui-organizationchart-node-content .ui-node-toggler {
  color: rgba(0, 0, 0, 0); }

/* TurboTable */
.ui-table .ui-table-thead > tr > th,
.ui-table .ui-table-tfoot > tr > td {
  background-color: transparent;
  border: 1px solid transparent;
  color: #334750; }

.ui-table .ui-table-tbody > tr {
  background-color: #fff;
  color: #333333; }
  .ui-table .ui-table-tbody > tr > td {
    border: 1px solid transparent;
    background-color: inherit; }
  .ui-table .ui-table-tbody > tr.ui-state-highlight {
    background-color: #fff;
    color: #86bc25; }
  .ui-table .ui-table-tbody > tr.ui-contextmenu-selected {
    background-color: white;
    color: #86bc25; }

.ui-table .ui-sortable-column .ui-sortable-column-icon {
  color: #334750; }

.ui-table .ui-sortable-column.ui-state-highlight {
  background-color: #fff;
  color: #86bc25; }
  .ui-table .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
    color: #86bc25; }

.ui-table .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #fff;
  color: #86bc25; }
  .ui-table .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
    color: #86bc25; }

.ui-table.ui-table-hoverable-rows .ui-table-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #fff;
  color: #86bc25; }

@media (max-width: 35em) {
  .ui-table-responsive .ui-table-tbody > tr {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent; } }

/* Row Reorder */
.ui-table .ui-table-tbody > tr.ui-table-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #fff; }

.ui-table .ui-table-tbody > tr.ui-table-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #fff; }

/* TurboTable */
.ui-treetable .ui-treetable-thead > tr > th,
.ui-treetable .ui-treetable-tfoot > tr > td {
  background-color: transparent;
  border: 1px solid transparent;
  color: #334750; }

.ui-treetable .ui-treetable-tbody > tr {
  background-color: #fff;
  color: #333333; }
  .ui-treetable .ui-treetable-tbody > tr > td {
    border: 1px solid transparent;
    background-color: inherit; }
  .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight {
    background-color: #fff;
    color: #86bc25; }
    .ui-treetable .ui-treetable-tbody > tr.ui-state-highlight .ui-treetable-toggler {
      color: #86bc25; }
  .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected {
    background-color: white;
    color: #86bc25; }
    .ui-treetable .ui-treetable-tbody > tr.ui-contextmenu-selected .ui-treetable-toggler {
      color: #86bc25; }
  .ui-treetable .ui-treetable-tbody > tr .ui-treetable-toggler {
    color: #333333; }

.ui-treetable .ui-sortable-column .ui-sortable-column-icon {
  color: #334750; }

.ui-treetable .ui-sortable-column.ui-state-highlight {
  background-color: #fff;
  color: #86bc25; }
  .ui-treetable .ui-sortable-column.ui-state-highlight .ui-sortable-column-icon {
    color: #86bc25; }

.ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover {
  background-color: #fff;
  color: #86bc25; }
  .ui-treetable .ui-sortable-column:not(.ui-state-highlight):hover .ui-sortable-column-icon {
    color: #86bc25; }

.ui-treetable.ui-treetable-hoverable-rows .ui-treetable-tbody > tr:not(.ui-state-highlight):not(.ui-contextmenu-selected):hover {
  cursor: pointer;
  background-color: #fff;
  color: #86bc25; }

@media (max-width: 35em) {
  .ui-treetable-responsive .ui-treetable-tbody > tr {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent; } }

/* Row Reorder */
.ui-treetable .ui-treetable-tbody > tr.ui-treetable-dragpoint-top > td {
  box-shadow: inset 0 2px 0 0 #fff; }

.ui-treetable .ui-treetable-tbody > tr.ui-treetable-dragpoint-bottom > td {
  box-shadow: inset 0 -2px 0 0 #fff; }

/* InputSwitch */
.ui-inputswitch .ui-inputswitch-slider {
  -webkit-transition: background-color .3s, box-shadow .2s;
  transition: background-color .3s, box-shadow .2s;
  background: #cccccc; }
  .ui-inputswitch .ui-inputswitch-slider:before {
    background-color: #ffffff; }

.ui-inputswitch.ui-inputswitch-focus .ui-inputswitch-slider {
  -moz-box-shadow: 0px 0px 5px #74b2e2;
  -webkit-box-shadow: 0px 0px 5px #74b2e2;
  box-shadow: 0px 0px 5px #74b2e2; }

.ui-inputswitch:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #b7b7b7; }

.ui-inputswitch.ui-inputswitch-checked .ui-inputswitch-slider {
  background-color: #186ba0; }

.ui-inputswitch.ui-inputswitch-checked:not(.ui-state-disabled):hover .ui-inputswitch-slider {
  background-color: #114d74; }

/* Validation */
.ui-inputtext.ng-dirty.ng-invalid,
p-dropdown.ng-dirty.ng-invalid > .ui-dropdown,
p-autocomplete.ng-dirty.ng-invalid > .ui-autocomplete > .ui-inputtext,
p-calendar.ng-dirty.ng-invalid > .ui-calendar > .ui-inputtext,
p-chips.ng-dirty.ng-invalid > .ui-inputtext,
p-inputmask.ng-dirty.ng-invalid > .ui-inputtext,
p-checkbox.ng-dirty.ng-invalid .ui-chkbox-box,
p-radiobutton.ng-dirty.ng-invalid .ui-radiobutton-box,
p-inputswitch.ng-dirty.ng-invalid .ui-inputswitch,
p-listbox.ng-dirty.ng-invalid .ui-inputtext,
p-multiselect.ng-dirty.ng-invalid > .ui-multiselect,
p-spinner.ng-dirty.ng-invalid > .ui-inputtext,
p-selectbutton.ng-dirty.ng-invalid .ui-button,
p-togglebutton.ng-dirty.ng-invalid .ui-button {
  border-bottom-color: #f10031; }

/* Cornering */
.ui-corner-tl {
  -moz-border-radius-topleft: 12px;
  -webkit-border-top-left-radius: 12px;
  border-top-left-radius: 12px; }

.ui-corner-tr {
  -moz-border-radius-topright: 12px;
  -webkit-border-top-right-radius: 12px;
  border-top-right-radius: 12px; }

.ui-corner-bl {
  -moz-border-radius-bottomleft: 12px;
  -webkit-border-bottom-left-radius: 12px;
  border-bottom-left-radius: 12px; }

.ui-corner-br {
  -moz-border-radius-bottomright: 12px;
  -webkit-border-bottom-right-radius: 12px;
  border-bottom-right-radius: 12px; }

.ui-corner-top {
  -moz-border-radius-topleft: 12px;
  -webkit-border-top-left-radius: 12px;
  border-top-left-radius: 12px;
  -moz-border-radius-topright: 12px;
  -webkit-border-top-right-radius: 12px;
  border-top-right-radius: 12px; }

.ui-corner-bottom {
  -moz-border-radius-bottomleft: 12px;
  -webkit-border-bottom-left-radius: 12px;
  border-bottom-left-radius: 12px;
  -moz-border-radius-bottomright: 12px;
  -webkit-border-bottom-right-radius: 12px;
  border-bottom-right-radius: 12px; }

.ui-corner-right {
  -moz-border-radius-topright: 12px;
  -webkit-border-top-right-radius: 12px;
  border-top-right-radius: 12px;
  -moz-border-radius-bottomright: 12px;
  -webkit-border-bottom-right-radius: 12px;
  border-bottom-right-radius: 12px; }

.ui-corner-left {
  -moz-border-radius-topleft: 12px;
  -webkit-border-top-left-radius: 12px;
  border-top-left-radius: 12px;
  -moz-border-radius-bottomleft: 12px;
  -webkit-border-bottom-left-radius: 12px;
  border-bottom-left-radius: 12px; }

.ui-corner-all {
  -moz-border-radius: 12px;
  -webkit-border-radius: 12px;
  border-radius: 12px; }

.ui-toast-message-info {
  color: #1765A3;
  background-color: #BFE0FA;
  border-color: #BFE0FA; }

.ui-toast-message-info .ui-toast-close-icon {
  color: #1765A3; }

.ui-toast-message-success {
  color: #2C832f;
  background-color: #B4F0B6;
  border-color: #B4F0B6; }

.ui-toast-message-success .ui-toast-close-icon {
  color: #2C832f; }

.ui-toast-message-warn {
  color: #8A6714;
  background-color: #FFE9B5;
  border-color: #FFE9B5; }

.ui-toast-message-warn .ui-toast-close-icon {
  color: #8A6714; }

.ui-toast-message-error {
  color: #AB1A0F;
  background-color: #FFCBC8;
  border-color: #FFCBC8; }

.ui-toast-message-error .ui-toast-close-icon {
  color: #AB1A0F; }

@media (min-width: 1200px) {
  .ui-dataview.ui-dataview-list > .ui-dataview-content > div.ui-g > div.col-lg-6 {
    width: 50%; } }

.ui-paginator-bottom {
  padding: 0; }

.ui-paginator .ui-paginator-current,
.ui-paginator .ui-paginator-first,
.ui-paginator .ui-paginator-last,
.ui-paginator .ui-paginator-page,
.ui-paginator .ui-paginator-pages {
  color: #333333; }

.ui-paginator .ui-state-active,
.ui-paginator .ui-state-active:hover {
  background: none;
  color: #000; }

.ui-paginator .ui-state-default {
  border: none; }

.ui-paginator-prev .ui-paginator-icon.pi.pi-caret-left,
.ui-paginator-next .ui-paginator-icon.pi.pi-caret-right {
  font-family: FontAwesome;
  font-size: 1.5em;
  color: #333333; }

.ui-paginator-prev .ui-paginator-icon.pi.pi-caret-left:before {
  content: "\f104"; }

.ui-paginator-next .ui-paginator-icon.pi.pi-caret-right:before {
  content: "\f105"; }

.ui-table {
  font-size: 12px; }

.ui-table-summary {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  background: #ececec; }

.ui-table .ui-table-tbody > tr > td:first-of-type,
.ui-table .ui-table-tfoot > tr > td:first-of-type,
.ui-table .ui-table-thead > tr > th:first-of-type {
  padding-left: .75em; }

@media screen and (max-width: 40em) {
  .ui-table .ui-table-tbody > tr > td:first-of-type,
  .ui-table .ui-table-tfoot > tr > td:first-of-type,
  .ui-table .ui-table-thead > tr > th:first-of-type {
    padding-left: .4em; } }

.ui-table .ui-table-tfoot > tr > td, .ui-table .ui-table-thead > tr > th {
  height: 50px; }

.ui-table .ui-table-thead > tr > th {
  opacity: .5;
  border-bottom: 2px solid #eee; }

.ui-table .ui-table-tbody > tr {
  font-size: 13px;
  color: #848e96;
  height: 50px; }

.ui-table .ui-table-tbody > tr:nth-of-type(odd) {
  background-color: #f9f9f9; }

.ui-dialog, .ui-dialog-footer {
  border-radius: 12px !important; }

.ui-dialog .ui-dialog-content {
  overflow: hidden; }

.ui-toast-icon.pi.pi-times,
.ui-toast-icon.pi.pi-info-circle,
.ui-toast-icon.pi.pi-exclamation-triangle,
.ui-toast-icon.pi.pi-check {
  font-family: FontAwesome;
  font-size: 1.5em; }

.ui-toast-icon.pi.pi-times:before {
  content: "\f057"; }

.ui-toast-icon.pi.pi-info-circle:before {
  content: "\f05a"; }

.ui-toast-icon.pi.pi-exclamation-triangle:before {
  content: "\f071"; }

.ui-toast-icon.pi.pi-check:before {
  content: "\f058"; }

.ui-corner-top {
  -moz-border-radius-topleft: 0;
  -webkit-border-top-left-radius: 0;
  border-top-left-radius: 0;
  -moz-border-radius-topright: 0;
  -webkit-border-top-right-radius: 0;
  border-top-right-radius: 0; }
