﻿$fontFamily: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
$fontSize: 1em;
$borderRadius: 12px;
$disabledOpacity: 0.45;

//Header
$headerBorderWidth: 0px;
$headerBorderColor: transparent;
$headerBgColor: transparent;
$headerTextColor: #334750;
$headerFontWeight: bold;
$headerIconTextColor: #222222;

//Content
$contentBorderWidth: 1px;
$contentBorderColor: transparent;
$contentBgColor: $white;
$contentTextColor: $grey-dark;

//Default State
$stateDefaultBorderWidth: 1px;
$stateDefaultBorderColor: transparent;
$stateDefaultBgColor: $white;
$stateDefaultTextColor: $brand-color;

//Active State
$stateActiveBorderColor: $brand-color;
$stateActiveBgColor: #EDF5DF;
$stateActiveTextColor: $white;

//Highlight State
$stateHighlightBorderColor: #edf5df;
$stateHighlightBgColor: $white;
$stateHighlightTextColor: $brand-color;

//Focus State
$stateFocusBorderColor: #74b2e2;
$stateFocusBgColor: #e4f1fb;
$stateFocusTextColor: #0070a3;

//Error State
$stateErrorBorderColor: #f10031;
$stateErrorBgColor: transparent;
$stateErrorTextColor: $white;

//Hover State
$stateHoverBorderColor: #d8d8d8;
$stateHoverBgColor: $white;
$stateHoverTextColor: $brand-color;

//Forms
$inputBgColor: transparent;
$inputTextColor: $grey-dark;
$invalidInputBorderColor: #f10031;
$inputGroupTextColor: #2779aa;